/*--------------------------------------------------------------
# Widgets
--------------------------------------------------------------*/

/* Make sure select elements fit in widgets. */
.widget {
	margin-top: 0;
	margin-bottom: 1.5em;
}

.widget-area .widget {
	margin-left: 0;
	margin-right: 0;
}

.widget li {
	line-height: 1.8;
}

.widget_archive ul,
.widget_categories ul,
.widget_meta ul,
.widget_nav_menu ul,
.widget_recent_comments ul,
.widget_pages ul,
.widget_recent_entries ul,
.widget_rss ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.widget select {
	max-width: 100%;
	text-overflow: ellipsis;
}

/* Archive and Categories widgets */
.widget_archive li {
	color: #7d7d7d;
}

.widget_categories .children {
	padding-top: 0;
}

/* Search widget. */
.widget_search .search-submit {
	display: none;
}

/* Pages and Menu widgets */
.widget_pages .children,
.widget_nav_menu .sub-menu {
	padding-top: 0;
	padding-bottom: 0.5em;
}

.widget_pages a,
.widget_nav_menu a {
	display: block;
	padding: 0.6em 0;
	text-decoration: none;
	border-bottom: 1px dashed #808080;
}

.widget_pages a:hover,
.widget_pages a:focus,
.widget_nav_menu a:hover,
.widget_nav_menu a:focus {
	text-decoration: none;
	border-bottom: 1px dashed #808080;
}

.widget_pages li,
.widget_nav_menu li {
	padding: 0;
}

.widget_pages li ul,
.widget_nav_menu li ul {
	margin-left: 1em;
}

/* Meta widget */
.widget_meta li {
	padding: 0;
}

/* Recent comments */
.widget_recent_comments li {
	line-height: 1.3;
	padding-bottom: 0.5em;
}

.widget_recent_comments a:hover,
.widget_recent_comments a:focus {
	padding-left: 0;
}

/* RSS widget */
.widget_rss li {
	margin-bottom: 1em;
	line-height: 1.3;
}

.widget li a.rsswidget {
	padding-right: 0.5em;
	font-size: 1em;
	line-height: 1.4;
}

.rss-date,
.widget_rss cite {
	color: #333;
	font-size: 0.8em;
}

.rssSummary {
	font-family: var(--global-font-family);
	padding: 0.5em 0;
	font-size: 0.9em;
	line-height: 1.3;
}

/* Search widget */
.widget_search input[type="search"] {
	box-sizing: border-box;
	width: 100%;
	padding: 0.5em 1em;
	border: 1px solid #c3c3c3;
	border-radius: 0;
}

/* Calendar widget */
.widget_calendar {
	font-weight: 400;
}

.widget_calendar caption {
	padding: 0.5em 0;
	font-size: 1em;
	font-weight: 500;
	text-align: left;
}

.widget_calendar thead {
	background: transparent;
	color: #333;
}

.widget_calendar th {
	border: none;
	text-align: center;
}

.widget_calendar thead th {
	border-bottom-width: 2px;
}

.widget_calendar td {
	padding: 0.2em;
	font-size: 0.8em;
	text-align: center;
	background: #fff;
	border: 1px solid #ccc;
}

.widget_calendar .pad {
	background: #eaeaea;
}

/* stylelint-disable */
.widget_calendar a {
	font-weight: 900;
	text-decoration: none;
}
/* stylelint-enable */

/* Text widget */
.textwidget {
	font-size: 80%;
	line-height: 1.3;
}

/* Footer widget stylings */
.footer-widgets {
	margin: 2em 0 0;
	padding: 1em 0;
	background-color: #1a1a1a;
}
